
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from "@/router";
import Materials, {
  exportMaterialData,
  exportPDFMaterials,
} from "@/core/data/material";
import {
  Material,
  getMaterials,
  deleteMaterial,
  cloneMaterial,
} from "@/core/data/material";
import moment from "moment";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getUserById, getUsers } from "@/core/data/users";
import { getError } from "@/core/helpers/utils";
import { PERMISSION_MODULE_NAME } from "@/core/data/permission";

export default defineComponent({
  name: "Material-listing",
  components: {
    Datatable,
  },
  setup() {
    const checkedMaterial = ref([]);
    const selectedStatus = ref("");

    let selectedItem = ref({});
    let selectedUser = ref();

    const total = ref<any>(0);
    const currentPage = ref<any>(1);
    const showSearchPanel = ref<boolean>(true);
    const selectAllMaterial = ref<boolean>(false);
    const isLoading = ref<boolean>(true);
    const search = ref<string>("");
    const tableData = ref<Array<Material>>(Materials);
    const userList = ref<any>({});
    const searchData = ref<any>({});
    const userOptionList = ref<any>([]);
    const userIdList = ref<Array<any>>([]);
    const branch = ref([]);
    const course = ref([]);
    const universiteies = ref([]);

    const cloneHandler = (id) => {
      if (confirm("Are You sure you want to clone this material?")) {
        cloneMaterial(id).then((data) => {
          Swal.fire({
            text: "Material Cloned successfully!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            setTimeout(() => {
              router.push({
                name: "apps-edit-material",
                params: { id: data.data.id },
              });
            }, 200);
          });
        });
      }
    };
    const getListItems = function (pageNumber) {
      console.log("selectedUser.value", selectedUser.value);
      isLoading.value = true;
      if (pageNumber) {
        currentPage.value = pageNumber;
      }
      localStorage.setItem(
        "MaterialListAPiFilterData",
        JSON.stringify({
          currentPage: currentPage.value,
          searchData: searchData.value,
          selectedStatus: selectedStatus.value,
          selectedUser: selectedUser.value,
        })
      );

      return new Promise((res, rej) => {
        getMaterials(
          currentPage.value,
          searchData.value,
          selectedStatus.value,
          selectedUser.value
        ).then((resObj: any) => {
          isLoading.value = false;
          total.value = resObj.count;
          tableData.value.splice(0, tableData.value.length, ...resObj.data);
          console.log("tableData", tableData.value);
          let promiseList: any = [];
          tableData.value.forEach((item) => {
            if (userIdList.value.indexOf(item.created_by) == -1) {
              userIdList.value.push(item.created_by);
              promiseList.push(getUserName(item.created_by));
            }
            Promise.all(promiseList).then(() => {
              res(resObj);
            });
          });
          console.log("tableData", tableData.value);
        });
      });
    };
    const getUserName = async function (id) {
      if (!userList.value[id]) {
        let userData = await getUserById(id);
        userList.value[id] = userData;
      }
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Materials Listing", ["Apps", "Materials"]);
      var materialListAPiFilterData: any = localStorage.getItem(
        "MaterialListAPiFilterData"
      );
      if (materialListAPiFilterData) {
        setTimeout(() => {
          materialListAPiFilterData = JSON.parse(materialListAPiFilterData);
          currentPage.value = materialListAPiFilterData.currentPage;
          searchData.value = materialListAPiFilterData.searchData;
          selectedStatus.value = materialListAPiFilterData.selectedStatus;
          selectedUser.value = materialListAPiFilterData.selectedUser;
          getListItems(currentPage.value);
        }, 500);
      } else {
        getListItems(1);
      }
    });

    const clearFilter = () => {
      currentPage.value = 1;
      searchData.value = {};
      selectedStatus.value = "";
      selectedUser.value = "";
      getListItems(1);
    };

    const userListComputed = computed(() => {
      return [
        { id: "", name: "All" },
        ...userOptionList.value.map((item) => ({
          name: item.name,
          ...item,
        })),
      ];
    });

    const asyncUser = (query) => {
      console.log("query", query);
      getUsers(1, query).then((resObj: any) => {
        userOptionList.value.splice(
          0,
          userOptionList.value.length,
          ...resObj.data
        );
      });
    };
    const deleteFewSites = () => {
      checkedMaterial.value.forEach((item) => {
        deleteMaterial(item);
      });
      checkedMaterial.value.length = 0;
    };
    const exportFewSites = () => {
      exportMaterialData(
        selectAllMaterial.value ? "all" : checkedMaterial.value.join(",")
      ).then(() => {
        Swal.fire("Export!", "Your Material has been exported.", "success");
      });
    };
    const exportPDFMaterialsHanlder = () => {
      exportPDFMaterials(
        selectAllMaterial.value ? "all" : checkedMaterial.value.join(",")
      ).then(() => {
        Swal.fire("Export!", "Your Material has been exported.", "success");
      });
    };
    const deleteMaterialItem = (item) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteMaterial(item)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "Your Material has been deleted.",
                "success"
              );
              getListItems(currentPage.value);
            })
            .catch((err) => {
              Swal.fire({
                html: getError(err),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };
    const searchItems = () => {
      getListItems(1);
    };

    const setSelectedItem = (item) => {
      selectedItem.value = item;
    };

    const addMaterial = (id) => {
      if (id) {
        router.push({ name: "apps-edit-material", params: { id: id } });
      } else {
        router.push({ name: "apps-add-material" });
      }
    };
    const stockMaterialItem = (id) => {
      router.push({ name: "apps-stock-material", params: { id: id } });
    };
    const onPageChange = (pageNumber) => {
      return getListItems(pageNumber);
    };

    const userData = computed(() => {
      return store.getters.currentUser;
    });

    const permissionData = computed(() => {
      return store.getters.currentPermission;
    });
    const hasCreate = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.MATERIAL].create
      );
    });
    const hasEdit = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.MATERIAL].update
      );
    });
    const hasDelete = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.MATERIAL].delete
      );
    });
    const hasStock = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.MATERIAL_STOCK].read
      );
    });
    const hasCost = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.MATERIAL].show_cost
      );
    });
    const tableHeader = computed(() => {
      let columnsArr = [
        {
          name: " ",
          key: "checkbox",
          sortable: false,
        },
        {
          name: "Code",
          key: "code",
          sortable: false,
        },
        {
          name: "Color",
          key: "color",
          sortable: false,
        },

        {
          name: "Unit",
          key: "unit",
          sortable: false,
        },
        {
          name: "photo",
          key: "image",
          sortable: false,
        },
      ];
      if (hasCost.value) {
        columnsArr.push({
          name: "Rate",
          key: "rate",
          sortable: false,
        });
        columnsArr.push({
          name: "Purchase Cost",
          key: "purchaseCost",
          sortable: false,
        });
      }
      if (hasEdit.value || hasDelete.value || hasStock.value) {
        columnsArr.push({
          name: "Actions",
          key: "actions",
          sortable: false,
        });
      }
      return columnsArr;
    });

    const onClickBack = () => {
      router.back();
    };

    return {
      addMaterial,
      setSelectedItem,
      selectedItem,
      tableData,
      tableHeader,
      deleteMaterial,
      getMaterials,
      deleteMaterialItem,
      search,
      searchItems,
      checkedMaterial,
      deleteFewSites,
      moment,
      total,
      currentPage,
      isLoading,
      onPageChange,
      userData,
      hasCreate,
      hasEdit,
      hasDelete,
      hasStock,
      selectedStatus,
      getUserName,
      userList,
      userListComputed,
      selectedUser,
      asyncUser,
      exportFewSites,
      exportPDFMaterialsHanlder,
      selectAllMaterial,
      showSearchPanel,
      searchData,
      branch,
      universiteies,
      course,
      stockMaterialItem,
      onClickBack,
      cloneHandler,
      clearFilter,
    };
  },
});
